// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: 0px;
$success: #7952b3;
$enable-negative-margins: true;

$grid-gutter-width: 48px;
$font-family-sans-serif: 'Fira Sans', Arial, Helvetica, sans-serif;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";